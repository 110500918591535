import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { IDMACategoryWithEffectsAndChildren, IPolicyData } from "@netcero/netcero-core-api-client";
import { AssociatedIROsSelectComponent } from "./associated-iros-select.component";

// TODO once actions have landed & the MDR api module introduced, maybe move the type there / use type from there
// (extract financialEffectIds / materialImpactIds into MDR schemas)
type FormValues = Pick<IPolicyData, "financialEffectIds" | "materialImpactIds">;

interface IAssociatedIROsControllersComponentProps {
  dmaCategories: IDMACategoryWithEffectsAndChildren[];
  disabled: boolean;
  label: string;
}

export const AssociatedIROsControllersComponent: FC<IAssociatedIROsControllersComponentProps> = ({
  disabled,
  label,
  dmaCategories,
}) => {
  const { control } = useFormContext<FormValues>();
  return (
    <Controller
      control={control}
      name="materialImpactIds"
      render={({ field: materialImpactField }) => (
        <Controller
          control={control}
          name="financialEffectIds"
          render={({ field: financialEffectField }) => (
            <AssociatedIROsSelectComponent
              label={label}
              dmaCategories={dmaCategories}
              financialEffectIds={financialEffectField.value}
              materialImpactIds={materialImpactField.value}
              onMaterialImpactIdsChange={materialImpactField.onChange}
              onFinancialEffectIdsChange={financialEffectField.onChange}
              // disable if any mutations are running
              disabled={disabled}
            />
          )}
        />
      )}
    />
  );
};
