import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  TextField,
} from "@mui/material";
import {
  IBaseDistributionCriterionData,
  IDistributionCriterion,
} from "@netcero/netcero-core-api-client";
import { FC, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ErrorTextComponent } from "../common/components/error-text.component";
import { DialogCancelButton, DialogSaveButton } from "../common/dialogs/dialog-button.components";

const getFormDefaultValues = (
  distributionCriteria?: IDistributionCriterion | null,
): IBaseDistributionCriterionData => {
  return {
    name: distributionCriteria?.name ?? "",
  };
};

interface IDistributionCriterionEditDialogProps {
  open: boolean;
  distributionCriterion?: IDistributionCriterion | null;
  loading: boolean;
  error?: Error | null;
  disabled?: boolean;
  onClose: (data: IBaseDistributionCriterionData | null) => void;
}

export const DistributionCriterionEditDialog: FC<IDistributionCriterionEditDialogProps> = ({
  open,
  distributionCriterion,
  loading,
  error,
  disabled,
  onClose,
}) => {
  const { t } = useTranslation("distribution_criteria_edit_dialog");

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset,
  } = useForm<IBaseDistributionCriterionData>({
    defaultValues: getFormDefaultValues(distributionCriterion),
  });

  useEffect(() => {
    if (open) {
      reset(getFormDefaultValues(distributionCriterion));
    }
  }, [open, distributionCriterion, reset]);

  const handleEmitData = (data: IBaseDistributionCriterionData) => {
    onClose({
      name: data.name.trim(),
    });
  };

  return (
    <Dialog open={open} onClose={!isDirty ? () => onClose(null) : undefined} fullWidth>
      <DialogTitle>{t(distributionCriterion ? "title_edit" : "title_create")}</DialogTitle>
      {loading && <LinearProgress />}
      <DialogContent>
        {error && <ErrorTextComponent error={error} />}
        <Box display="flex" flexDirection="column" gap={2} mt={1}>
          {/* Name Input */}
          <Controller
            control={control}
            name="name"
            rules={{ required: t("error_name_required") }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                label={t("label_name")}
                {...field}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <DialogCancelButton onClick={() => onClose(null)} disabled={disabled}>
          {t(isDirty ? "cancel" : "close", { ns: "buttons" })}
        </DialogCancelButton>
        <DialogSaveButton onClick={handleSubmit(handleEmitData)} disabled={disabled}>
          {t("save", { ns: "buttons" })}
        </DialogSaveButton>
      </DialogActions>
    </Dialog>
  );
};
