import {
  IDMACategoryWithEffectsAndChildren,
  IStakeholderFeedback,
  IStakeholderFeedbackDetails,
  ISubmitStakeholderFeedbackRequest,
} from "@netcero/netcero-core-api-client";
import { FC, useCallback } from "react";
import { Box, Typography } from "@mui/material";
import { StakeholderFeedbackCategoryComponent } from "./stakeholder-feedback-category.component";
import { StakeholderFeedbackDialog } from "../dialogs/stakeholder-feedback.dialog";
import { useDialogState } from "../../common/dialogs/dialog-state.hook";
import { StakeholderFeedbackUtilities } from "../utilities/stakeholder-feedback.utitilities";
import { useSubmitStakeholderFeedbackMutation } from "../stakeholder-feedbacks.mutations";
import { useTranslation } from "react-i18next";

interface IStakeholderFeedbacksComponentProps {
  stakeholderId: string;
  recordingPeriodId: string;
  details: IStakeholderFeedbackDetails;
}

interface IStakeholderFeedbackDialogState {
  feedback: IStakeholderFeedback;
  category: Pick<
    IDMACategoryWithEffectsAndChildren,
    "id" | "name" | "materiality" | "optOutReason"
  >;
  create: boolean;
}

export const StakeholderFeedbacksComponent: FC<IStakeholderFeedbacksComponentProps> = ({
  stakeholderId,
  details,
  recordingPeriodId,
}) => {
  const { t } = useTranslation("stakeholder_feedbacks_component");

  // Feedback dialog
  const { isOpen, openDialog, closeDialog, data } =
    useDialogState<IStakeholderFeedbackDialogState>();

  const submitStakeholderFeedbackMutation = useSubmitStakeholderFeedbackMutation();

  const handleSubmitFeedbackDialog = useCallback(
    async (data: { categoryId: string; feedback: ISubmitStakeholderFeedbackRequest } | null) => {
      if (data) {
        await submitStakeholderFeedbackMutation.mutateAsync({
          stakeholderId,
          dmaCategoryId: data.categoryId,
          payload: data.feedback,
          recordingPeriodId,
        });
      }
      closeDialog();
    },
    [submitStakeholderFeedbackMutation, closeDialog, stakeholderId, recordingPeriodId],
  );

  return (
    <>
      <StakeholderFeedbackDialog
        open={isOpen}
        onClose={handleSubmitFeedbackDialog}
        feedback={data?.feedback ?? null}
        category={data ? data.category : null}
        createMode={data?.create || false}
        loading={submitStakeholderFeedbackMutation.isPending}
        disabled={submitStakeholderFeedbackMutation.isPending}
        error={
          submitStakeholderFeedbackMutation.isError ? submitStakeholderFeedbackMutation.error : null
        }
      />
      <Box width="100%">
        {/* Display message if no feedback has been requested yet*/}
        {details.dmaCategories.length === 0 && (
          <Typography mt={3}>{t("no_feedback_requested")}</Typography>
        )}

        {/* Render feedback */}
        {details.dmaCategories.map((c) => (
          <StakeholderFeedbackCategoryComponent
            onShowFeedbackDialog={(feedback, category) => {
              submitStakeholderFeedbackMutation.reset();
              openDialog({
                feedback,
                create: !StakeholderFeedbackUtilities.hasFeedbackBeenSubmitted(feedback),
                category,
              });
            }}
            stakeholderId={stakeholderId}
            category={c}
            key={c.id}
            disabled={submitStakeholderFeedbackMutation.isPending}
          />
        ))}
      </Box>
    </>
  );
};
