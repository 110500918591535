import { IInputParameterValueMetaData } from "@netcero/netcero-core-api-client";
import { FC, useMemo } from "react";
import { NumberInputComponent } from "../../value-acquisition/input-components/number-input.component";
import { BooleanInputComponent } from "../../value-acquisition/input-components/boolean-input.component";
import { OptionsInputComponent } from "../../value-acquisition/input-components/options-input.component";
import { TextInputComponent } from "../../value-acquisition/input-components/text-input.component";
import { DateInputComponent } from "../../value-acquisition/input-components/date-input.component";
import { DateRangeInputComponent } from "../../value-acquisition/input-components/date-range-input.component";
import { CurrencyInputComponent } from "../../value-acquisition/input-components/currency-input.component";
import {
  DataEntryObjectInputParameterValueDefinitionForBoolean,
  DataEntryObjectInputParameterValueDefinitionForCurrency,
  DataEntryObjectInputParameterValueDefinitionForDate,
  DataEntryObjectInputParameterValueDefinitionForDateRange,
  DataEntryObjectInputParameterValueDefinitionForMass,
  DataEntryObjectInputParameterValueDefinitionForNestedOptions,
  DataEntryObjectInputParameterValueDefinitionForNumber,
  DataEntryObjectInputParameterValueDefinitionForOptions,
  DataEntryObjectInputParameterValueDefinitionForPolicy,
  DataEntryObjectInputParameterValueDefinitionForText,
  DataEntryObjectInputParameterValueDefinitionForYear,
  DataEntryObjectInputParameterValuesVerification,
  OptionalDataEntryObjectInputParameterValueDefinition,
  OptionalDefinition,
} from "@netcero/netcero-common";
import { NestedOptionsInputComponent } from "../../value-acquisition/input-components/nested-options-input.component";
import { PoliciesListComponent } from "../../policies/components/policies-list.component";
import { SxProps, Theme } from "@mui/material";
import { ESRS_VALUE_INPUT_STYLES } from "../esrs/value-editing/esrs-value-input.constants";
import { FormControlProps } from "@mui/material/FormControl/FormControl";
import { DateYearInputComponent } from "../../value-acquisition/input-components/date-year-input.component";
import { MassInputComponent } from "../../value-acquisition/input-components/mass-input.component";

export type IDataEntryObjectValueInputVariant = "esrs" | "thg" | "table";
export const STYLES_FOR_TYPE: Record<IDataEntryObjectValueInputVariant, SxProps<Theme>> = {
  esrs: ESRS_VALUE_INPUT_STYLES,
  thg: {}, // Default Styles
  table: {}, // Default Styles
};
export const useVariantSxStyles = (variant: IDataEntryObjectValueInputVariant) => {
  return useMemo(() => {
    return STYLES_FOR_TYPE[variant];
  }, [variant]);
};

export const useVariantFormProps = (
  variant: IDataEntryObjectValueInputVariant,
): Pick<FormControlProps, "variant" | "size"> => {
  return useMemo(() => {
    switch (variant) {
      case "table":
        return {
          variant: "standard",
          size: "small",
        };
    }
    return {
      variant: "outlined",
      size: "medium",
    };
  }, [variant]);
};

interface IDataEntryObjectValueInputComponentProps {
  variant: IDataEntryObjectValueInputVariant;
  valueMetaData: IInputParameterValueMetaData;
  value: OptionalDataEntryObjectInputParameterValueDefinition;
  onChange: (value: OptionalDataEntryObjectInputParameterValueDefinition) => void;
  onSubmit?: () => void;
  disabled?: boolean;
  error?: string;
  disableMaxWidth?: boolean;
  stackDateRange?: boolean;
  label?: string;
  required?: boolean;
  autoFocus?: boolean;
  associationData?: {
    organizationId: string;
    recordingPeriodId: string;
    dataEntryObjectId: string;
    rootDataEntryObjectId: string;
  };
}

export const DataEntryObjectValueInputComponent: FC<IDataEntryObjectValueInputComponentProps> = ({
  variant,
  valueMetaData,
  value,
  label,
  onChange,
  onSubmit,
  disabled,
  error,
  disableMaxWidth,
  stackDateRange,
  required,
  autoFocus,
  associationData,
}) => {
  const handleValueChange = (value: OptionalDataEntryObjectInputParameterValueDefinition) => {
    // be sure to sanitize value, otherwise it won't pass validation (trim...)
    const sanitized = DataEntryObjectInputParameterValuesVerification.sanitizeValue(
      value,
      valueMetaData,
      true,
    );

    // propagate changes
    onChange(sanitized);
  };

  if (valueMetaData.type === "number") {
    return (
      <NumberInputComponent
        variant={variant}
        disableMaxWidth={disableMaxWidth}
        metaData={valueMetaData}
        value={value as OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForNumber>}
        onChange={handleValueChange}
        onSubmit={onSubmit}
        disabled={disabled}
        error={error}
        label={label}
        required={required}
        autoFocus={autoFocus}
      />
    );
  } else if (valueMetaData.type === "boolean") {
    return (
      <BooleanInputComponent
        variant={variant}
        disableMaxWidth={disableMaxWidth}
        metaData={valueMetaData}
        value={value as OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForBoolean>}
        onChange={handleValueChange}
        disabled={disabled}
        error={error}
        label={label}
        required={required}
      />
    );
  } else if (valueMetaData.type === "options") {
    return (
      <OptionsInputComponent
        variant={variant}
        disableMaxWidth={disableMaxWidth}
        metaData={valueMetaData}
        value={value as OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForOptions>}
        onChange={handleValueChange}
        disabled={disabled}
        error={error}
        label={label}
        required={required}
      />
    );
  } else if (valueMetaData.type === "nested-options") {
    return (
      <NestedOptionsInputComponent
        variant={variant}
        metaData={valueMetaData}
        value={
          value as OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForNestedOptions>
        }
        onChange={handleValueChange}
        disabled={disabled}
        error={error}
        label={label}
        required={required}
        disableMaxWidth={disableMaxWidth}
      />
    );
  } else if (valueMetaData.type === "text") {
    return (
      <TextInputComponent
        variant={variant}
        metaData={valueMetaData}
        value={value as OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForText>}
        onChange={handleValueChange}
        disabled={disabled}
        error={error}
        label={label}
        required={required}
        disableMaxWidth={disableMaxWidth}
        autoFocus={autoFocus}
      />
    );
  } else if (valueMetaData.type === "date") {
    return (
      <DateInputComponent
        variant={variant}
        value={value as OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForDate>}
        onChange={handleValueChange}
        metaData={valueMetaData}
        disabled={disabled}
        error={error}
        disableMaxWidth={disableMaxWidth}
        label={label}
        required={required}
      />
    );
  } else if (valueMetaData.type === "date-range") {
    return (
      <DateRangeInputComponent
        variant={variant}
        value={
          value as OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForDateRange>
        }
        onChange={handleValueChange}
        metaData={valueMetaData}
        disabled={disabled}
        error={error}
        disableMaxWidth={disableMaxWidth}
        stack={stackDateRange}
        required={required}
        label={label}
      />
    );
  } else if (valueMetaData.type === "currency") {
    return (
      <CurrencyInputComponent
        variant={variant}
        value={value as OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForCurrency>}
        onChange={handleValueChange}
        metaData={valueMetaData}
        disabled={disabled}
        error={error}
        disableMaxWidth={disableMaxWidth}
        label={label}
        required={required}
      />
    );
  } else if (valueMetaData.type === "policy") {
    if (!associationData) {
      return "Association Data is required for Policy Input!".toUpperCase();
    }
    return (
      <PoliciesListComponent
        organizationId={associationData.organizationId}
        recordingPeriodId={associationData.recordingPeriodId}
        dataEntryObjectId={associationData.dataEntryObjectId}
        rootDataEntryObjectId={associationData.rootDataEntryObjectId}
        value={value as OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForPolicy>}
        onChange={handleValueChange}
        topicIdentifier={valueMetaData.esrsTopicIdentifier}
        disabled={disabled}
      />
    );
  } else if (valueMetaData.type === "year") {
    return (
      <DateYearInputComponent
        variant={variant}
        value={value as OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForYear>}
        onChange={handleValueChange}
        metaData={valueMetaData}
        disabled={disabled}
        error={error}
        disableMaxWidth={disableMaxWidth}
        label={label}
        required={required}
      />
    );
  } else if (valueMetaData.type === "mass") {
    return (
      <MassInputComponent
        variant={variant}
        disableMaxWidth={disableMaxWidth}
        metaData={valueMetaData}
        value={value as OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForMass>}
        onChange={handleValueChange}
        onSubmit={onSubmit}
        disabled={disabled}
        error={error}
        label={label}
        required={required}
        autoFocus={autoFocus}
      />
    );
  }

  // TODO @marckornberger: Please handle the case of "action" and "target" (as type)
  // TODO gracefully :pray:
  return "Unsupported Type!".toUpperCase();
};
