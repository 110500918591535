import { ErrorCode } from "./error-code.enum";
import { IError } from "./errors.public-types";

// Type acrobatics could be handled better
// May be subject to change

export const ERRORS: { [key in ErrorCode]: IError } = {
  "general.entity_not_found": {
    errorCode: ErrorCode["general.entity_not_found"],
    message: "Entity not found",
  },
  "general.internal_server_error": {
    errorCode: ErrorCode["general.internal_server_error"],
    message: "An internal server error occurred.",
  },
  "auth.unauthorized": {
    errorCode: ErrorCode["auth.unauthorized"],
    message: "Unauthorized",
  },
  "input_parameter.not_found": {
    errorCode: ErrorCode["input_parameter.not_found"],
    message: "Input Parameter for provided UUID was not found.",
  },
  "organization.not_found": {
    errorCode: ErrorCode["organization.not_found"],
    message: "Organization for provided UUID was not found.",
  },
  "recording_period.not_found": {
    errorCode: ErrorCode["recording_period.not_found"],
    message: "Recording Period for provided UUID was not found for the provided Organization UUID.",
  },
  "recording_period.previous_structure_is_draft": {
    errorCode: ErrorCode["recording_period.previous_structure_is_draft"],
    message: "A new recording period can't be created because the previous structure is a draft.",
  },
  "recording_period.value_would_move_outside_scope": {
    errorCode: ErrorCode["recording_period.value_would_move_outside_scope"],
    message:
      "The recording period start and end dates cannot be updated to the specified values since this would move value entries outside the scope of the new recording period.",
  },
  "recording_period.overlaps_with_existing_recording_period": {
    errorCode: ErrorCode["recording_period.overlaps_with_existing_recording_period"],
    message: "The recording period start or end dates overlap with an existing recording period.",
  },
  "recording_model.not_found": {
    errorCode: ErrorCode["recording_model.not_found"],
    message: "Recording Model for provided UUID was not found.",
  },
  "recording_model_templates_group.not_found": {
    errorCode: ErrorCode["recording_model_templates_group.not_found"],
    message: "Recording Model Templates Group for provided UUID and Recording Model was not found.",
  },
  // IP Recording Structure
  "input_parameter_recording_structure.not_found": {
    errorCode: ErrorCode["input_parameter_recording_structure.not_found"],
    message:
      "Input Parameter Recording Structure for provided UUID and for Recording Period and Organization was not found.",
  },
  "input_parameter_recording_structure.input_parameter_used_more_than_once": {
    errorCode: ErrorCode["input_parameter_recording_structure.input_parameter_used_more_than_once"],
    message: "At least one input parameter was used more than once in the recording structure.",
  },
  "input_parameter_recording_structure.input_parameter_not_found": {
    errorCode: ErrorCode["input_parameter_recording_structure.input_parameter_not_found"],
    message: "At least one input parameter used in the recording structure was not found.",
  },
  "input_parameter_recording_structure.order_in_use": {
    errorCode: ErrorCode["input_parameter_recording_structure.order_in_use"],
    message:
      "Order Number is already in use by another recording structure for the same Recording Model.",
  },
  "input_parameter_recording_structure.of_wrong_type": {
    errorCode: ErrorCode["input_parameter_recording_structure.of_wrong_type"],
    message: "This type of recording structure is invalid for the current operation!",
  },
  // IP Recording Structure Template
  "input_parameter_recording_structure_template.not_found": {
    errorCode: ErrorCode["input_parameter_recording_structure_template.not_found"],
    message:
      "Input Parameter Recording Structure Template for provided UUID and Recording Model TODO: ADD was not found.",
  },
  // Organization structure
  "organization_structure.not_found": {
    errorCode: ErrorCode["organization_structure.not_found"],
    message: "Organization structure for provided UUID was not found.",
  },
  // Data entry object
  "data_entry_object.not_found": {
    errorCode: ErrorCode["data_entry_object.not_found"],
    message:
      "Dat" +
      "a Entry Object with provided UUID was not found for given Recording Period and Organization.",
  },
  "data_entry_object.next_already_set": {
    errorCode: ErrorCode["data_entry_object.next_already_set"],
    message: "Another data entry object already references the provided data entry object as next.",
  },
  // Data entry object input parameter
  "data_entry_object_input_parameter.not_found": {
    errorCode: ErrorCode["data_entry_object_input_parameter.not_found"],
    message:
      "Date Entry Object Input Parameter with provided UUID was not found for given Recording Period and Organization.",
  },
  "data_entry_object_input_parameter.state_transition_not_possible": {
    errorCode: ErrorCode["data_entry_object_input_parameter.state_transition_not_possible"],
    message:
      "Given the current state of the DEOIP and its relations, the requested state transition is not possible.",
  },
  "data_entry_object_input_parameter.current_user_is_not_responsible": {
    errorCode: ErrorCode["data_entry_object_input_parameter.current_user_is_not_responsible"],
    message:
      "The current user is not responsible for this DEOIP. Therefore, the action is invalid.",
  },
  "data_entry_object_input_parameter.already_excluded": {
    errorCode: ErrorCode["data_entry_object_input_parameter.already_excluded"],
    message: "This parameter has already been excluded!",
  },
  "data_entry_object_input_parameter.not_yet_excluded": {
    errorCode: ErrorCode["data_entry_object_input_parameter.not_yet_excluded"],
    message: "This parameter has not been excluded yet!",
  },
  // Organization structure
  "organization_structure.not_yet_committed": {
    errorCode: ErrorCode["organization_structure.not_yet_committed"],
    message: "Organization structure has not yet been committed.",
  },
  "organization_structure.already_committed": {
    errorCode: ErrorCode["organization_structure.already_committed"],
    message:
      "The specified organization structure has already been committed and can therefore no longer be edited.",
  },
  "input_parameter_recording_method.not_found": {
    errorCode: ErrorCode["input_parameter_recording_method.not_found"],
    message: "Input Parameter Recording Method with provided UUID was not found.",
  },
  // Data Entry Object Input Parameter Value
  "data_entry_object_input_parameter_value.bad_value": {
    errorCode: ErrorCode["data_entry_object_input_parameter_value.bad_value"],
    message:
      "The submitted value does not match the value limitations defined by the used recording method for the given input parameter.",
  },
  "data_entry_object_input_parameter_value.not_found": {
    errorCode: ErrorCode["data_entry_object_input_parameter_value.not_found"],
    message:
      "No value entry for the provided UUID was found for the provided Data Entry Object and Input Parameter.",
  },
  "data_entry_object_input_parameter_value.value_outside_recording_period": {
    errorCode: ErrorCode["data_entry_object_input_parameter_value.value_outside_recording_period"],
    message: "The provided value entry does not fall within the recording period.",
  },
  "data_entry_object_input_parameter_value.does_not_support_dc": {
    errorCode: ErrorCode["data_entry_object_input_parameter_value.does_not_support_dc"],
    message:
      "The recording method specified for the input parameter value does not support distribution criteria.",
  },
  "data_entry_object_input_parameter_value.dc_is_not_applied": {
    errorCode: ErrorCode["data_entry_object_input_parameter_value.dc_is_not_applied"],
    message:
      "The chosen distribution criteria has not been applied to the organization structure that is referenced by the current data entry object.",
  },
  "data_entry_object_input_parameter_value.invalid_date_range": {
    errorCode: ErrorCode["data_entry_object_input_parameter_value.invalid_date_range"],
    message:
      "The date range specified for the input parameter value is invalid (Start must be less than end).",
  },
  "data_entry_object_input_parameter_value.no_date_allowed": {
    errorCode: ErrorCode["data_entry_object_input_parameter_value.no_date_allowed"],
    message:
      "The IP dictates that only one value may be recorded per recording period. Therefore, information regarding start/end dates is not allowed.",
  },
  "data_entry_object_input_parameter_value.data_quality_not_supported": {
    errorCode: ErrorCode["data_entry_object_input_parameter_value.data_quality_not_supported"],
    message: "The input parameter does not support data quality.",
  },
  "data_entry_object_input_parameter_value.duplicate_identifying_table_values": {
    errorCode:
      ErrorCode["data_entry_object_input_parameter_value.duplicate_identifying_table_values"],
    message: "Duplicate identifying table values were identified.",
  },
  "data_entry_object_input_parameter.phase_in_only_possible_for_eligible_dr": {
    errorCode:
      ErrorCode["data_entry_object_input_parameter.phase_in_only_possible_for_eligible_dr"],
    message: "Choosing Phase-In as the reason is only possible for eligible DRs!",
  },
  // Organization Structure
  "organization_structure.duplicate_ids": {
    errorCode: ErrorCode["organization_structure.duplicate_ids"],
    message:
      "The provided structure contains duplicate data entry object IDs. Each ID can only be used once in the structure.",
  },
  "organization_structure.id_doesnt_belong_to_previous_structure": {
    errorCode: ErrorCode["organization_structure.id_doesnt_belong_to_previous_structure"],
    message:
      "The provided structure contains data entry object IDs that do not belong to the previous structure.",
  },
  "data_entry_object_input_parameter_value.value_already_recorded_for_period": {
    errorCode:
      ErrorCode["data_entry_object_input_parameter_value.value_already_recorded_for_period"],
    message:
      "A value for the provided input parameter and data entry object has already been recorded for the determined period.",
  },
  // Dma Categories
  "dma_category.not_found": {
    errorCode: ErrorCode["dma_category.not_found"],
    message: "DMA Category with provided UUID was not found.",
  },
  "dma_category.has_children": {
    errorCode: ErrorCode["dma_category.has_children"],
    message: "The specified DMA Category has children and can therefore not be deleted.",
  },
  "dma_category.has_associated_financial_effects": {
    errorCode: ErrorCode["dma_category.has_associated_financial_effects"],
    message:
      "The specified DMA Category has associated financial effects and can therefore not be deleted.",
  },
  "dma_category.has_associated_material_impacts": {
    errorCode: ErrorCode["dma_category.has_associated_material_impacts"],
    message:
      "The specified DMA Category has associated material impacts and can therefore not be deleted.",
  },
  "dma_category.opt_out_of_category_with_iros": {
    errorCode: ErrorCode["dma_category.opt_out_of_category_with_iros"],
    message: "You can't opt out of recording a category that has associated IROs.",
  },
  "dma_category.opt_out_without_reason": {
    errorCode: ErrorCode["dma_category.opt_out_without_reason"],
    message: "You can't opt out of a category without specifying a reason.",
  },
  "dma_category.opt_in_with_reason": {
    errorCode: ErrorCode["dma_category.opt_in_with_reason"],
    message: "You must not provide a reason when opting into recording a category.",
  },
  "dma_category.has_opted_out": {
    errorCode: ErrorCode["dma_category.has_opted_out"],
    message:
      "The specified category has opted out of recording IROs. Therefore, the current operation is invalid.",
  },
  "dma_category.no_feedback_found": {
    errorCode: ErrorCode["dma_category.no_feedback_found"],
    message:
      "No feedback was found for the specified category. Therefore, the feedback can't be verified.",
  },
  "dma_category.in_invalid_state": {
    errorCode: ErrorCode["dma_category.in_invalid_state"],
    message:
      "The specified category or one of its children is in a state that prohibits the current operation.",
  },
  "dma_category.already_verified": {
    errorCode: ErrorCode["dma_category.already_verified"],
    message:
      "The specified category has already been verified (at least for the given IRO type). Therefore, the current operation is invalid.",
  },
  "dma_category.has_associated_feedback": {
    errorCode: ErrorCode["dma_category.has_associated_feedback"],
    message: "The given DMA category has associated feedback and can therefore not be deleted.",
  },
  "dma_category.cannot_be_altered": {
    errorCode: ErrorCode["dma_category.cannot_be_altered"],
    message: "The given DMA category is static and can therefore not be altered.",
  },
  // Dma Effects
  "dma_effect.reason_was_not_provided": {
    errorCode: ErrorCode["dma_effect.reason_was_not_provided"],
    message: "No reason was provided for the effect, even though the materiality was overridden.",
  },
  "dma_effect.reason_was_provided_without_override": {
    errorCode: ErrorCode["dma_effect.reason_was_provided_without_override"],
    message:
      "A reason was provided for the effect, even though no materiality override was specified.",
  },
  "dma_effect.associated_data_entry_object_is_not_child": {
    errorCode: ErrorCode["dma_effect.associated_data_entry_object_is_not_child"],
    message: "The specified DEO is not a child of the DEO that is used for the DMA.",
  },
  // Financial effects
  "dma_financial_effect.not_found": {
    errorCode: ErrorCode["dma_financial_effect.not_found"],
    message: "DMA Financial Effect with provided UUID was not found for the provided category.",
  },
  "dma_financial_effect.not_responsible_user": {
    errorCode: ErrorCode["dma_financial_effect.not_responsible_user"],
    message: "The user is not responsible for the specified financial effect.",
  },
  "dma_financial_effect.already_published": {
    errorCode: ErrorCode["dma_financial_effect.already_published"],
    message:
      "DMA Financial Effect with provided UUID has already been published and can therefore no longer be updated / deleted.",
  },
  [ErrorCode["dma_financial_effect.incomplete_for_shared"]]: {
    errorCode: ErrorCode["dma_financial_effect.incomplete_for_shared"],
    message:
      "The financial effect is incomplete and can therefore not yet be set to status shared.",
  },
  [ErrorCode["dma_financial_effect.not_shared"]]: {
    errorCode: ErrorCode["dma_financial_effect.not_shared"],
    message:
      "The financial effect has not yet been shared. Therefore, the current operation is invalid.",
  },
  [ErrorCode["dma_financial_effect.referenced_in_policies"]]: {
    errorCode: ErrorCode["dma_financial_effect.referenced_in_policies"],
    message:
      "The financial effect is referenced in policies. Therefore, the state cannot be reset to 'draft'.",
  },
  // Material effects
  "dma_material_impact.not_found": {
    errorCode: ErrorCode["dma_material_impact.not_found"],
    message: "DMA Material Impact with provided UUID was not found for the provided category.",
  },
  "dma_material_impact.human_rights_was_not_provided": {
    errorCode: ErrorCode["dma_material_impact.human_rights_was_not_provided"],
    message:
      "The effect on human rights was not provided for the material impact, even though the irreversibility was not null.",
  },
  "dma_material_impact.human_rights_was_provided_without_irreversibility": {
    errorCode: ErrorCode["dma_material_impact.human_rights_was_provided_without_irreversibility"],
    message:
      "The effect on human rights was provided for the material impact, even though the irreversibility was null.",
  },
  "dma_material_impact.not_responsible_user": {
    errorCode: ErrorCode["dma_material_impact.not_responsible_user"],
    message: "The user is not responsible for the specified material impact.",
  },
  "dma_material_impact.already_published": {
    errorCode: ErrorCode["dma_material_impact.already_published"],
    message:
      "DMA Material Impact with provided UUID has already been published and can therefore no longer be updated / deleted.",
  },
  [ErrorCode["dma_material_impact.incomplete_for_shared"]]: {
    errorCode: ErrorCode["dma_material_impact.incomplete_for_shared"],
    message: "The material impact is incomplete and can therefore not yet be set to status shared.",
  },
  [ErrorCode["dma_material_impact.not_shared"]]: {
    errorCode: ErrorCode["dma_material_impact.not_shared"],
    message:
      "The material impact has not yet been shared. Therefore, the current operation is invalid.",
  },
  [ErrorCode["dma_material_impact.referenced_in_policies"]]: {
    errorCode: ErrorCode["dma_material_impact.referenced_in_policies"],
    message:
      "The material impact is referenced in policies. Therefore, the state cannot be reset to 'draft'.",
  },
  // Recorded ESRS Topics
  "recorded_esrs_topic.opt_out_of_topic_with_categories": {
    errorCode: ErrorCode["recorded_esrs_topic.opt_out_of_topic_with_categories"],
    message: "You can't opt out of recording a topic that has categories.",
  },
  "recorded_esrs_topic.does_not_exist": {
    errorCode: ErrorCode["recorded_esrs_topic.does_not_exist"],
    message:
      "The specified recorded ESRS topic does not exist. Therefore, the selected parent is invalid.",
  },
  "recorded_esrs_topic.category_is_not_child": {
    errorCode: ErrorCode["recorded_esrs_topic.category_is_not_child"],
    message: "The specified category is not a child of the specified recorded ESRS topic.",
  },
  "recorded_esrs_topic.opt_out_without_reason": {
    errorCode: ErrorCode["recorded_esrs_topic.opt_out_without_reason"],
    message: "You can't opt out of a topic without specifying a reason.",
  },
  "recorded_esrs_topic.opt_in_with_reason": {
    errorCode: ErrorCode["recorded_esrs_topic.opt_in_with_reason"],
    message: "You must not provide a reason when opting into recording a topic.",
  },
  // ESRS Topics
  "esrs_topic.not_found": {
    errorCode: ErrorCode["esrs_topic.not_found"],
    message: "ESRS Topic with provided UUID was not found.",
  },
  "esrs_topic.does_not_support_custom_categories": {
    errorCode: ErrorCode["esrs_topic.does_not_support_custom_categories"],
    message: "The specified ESRS topic does not support (adding) custom categories.",
  },
  "esrs_topic.does_not_support_phase_in": {
    errorCode: ErrorCode["esrs_topic.does_not_support_phase_in"],
    message: "The chosen ESRS topic does not support phase in.",
  },
  // DMA Execution
  "dma_execution.invalid_category": {
    errorCode: ErrorCode["dma_execution.invalid_category"],
    message: "An invalid category was discovered when executing the double materiality assessment.",
  },
  "dma_execution.feedback_missing": {
    errorCode: ErrorCode["dma_execution.feedback_missing"],
    message:
      "A category without feedback was discovered when executing the double materiality assessment.",
  },
  "data_entry_object_input_parameter.attribution_reason_not_present": {
    errorCode: ErrorCode["data_entry_object_input_parameter.attribution_reason_not_present"],
    message:
      "The attribution reason must be provided when the attribution factor is not 1 or null.",
  },
  // Stakeholders
  "stakeholder.not_found": {
    errorCode: ErrorCode["stakeholder.not_found"],
    message: "Stakeholder with provided UUID was not found for the given Organization.",
  },
  "stakeholder.has_associated_feedback": {
    errorCode: ErrorCode["stakeholder.has_associated_feedback"],
    message: "The given stakeholder has associated feedback and can therefore not be deleted.",
  },
  // Stakeholder Feedback
  "stakeholder_feedback.not_found": {
    errorCode: ErrorCode["stakeholder_feedback.not_found"],
    message: "No feedback was requested for the given stakeholder and DMA category.",
  },
  "stakeholder_feedback.invalid": {
    errorCode: ErrorCode["stakeholder_feedback.invalid"],
    message:
      "The provided feedback is invalid, as neither a verbal feedback nor a slider rating was specified.",
  },
  "stakeholder_feedback.already_submitted": {
    errorCode: ErrorCode["stakeholder_feedback.already_submitted"],
    message:
      "Feedback for the given stakeholder and DMA category has already been submitted. Therefore, the request can no longer be revoked.",
  },
  "stakeholder_feedback.already_requested": {
    errorCode: ErrorCode["stakeholder_feedback.already_requested"],
    message: "Feedback of this type has already been requested for this stakeholder and category.",
  },
  // Manual Stakeholder Feedback
  "manual_stakeholder_feedback.not_found": {
    errorCode: ErrorCode["manual_stakeholder_feedback.not_found"],
    message:
      "The manual stakeholder feedback with the provided UUID was not found for given DMA category.",
  },
  // DMA Configuration
  "dma_configuration.steps_not_ascending": {
    errorCode: ErrorCode["dma_configuration.steps_not_ascending"],
    message:
      "The given steps for the slider are not ascending and therefore invalid. Please provide ascending steps!",
  },
  "dma_configuration.not_found": {
    errorCode: ErrorCode["dma_configuration.not_found"],
    message: "No DMA configuration was found for the given recording period.",
  },
  "dma_configuration.already_exists": {
    errorCode: ErrorCode["dma_configuration.already_exists"],
    message: "A DMA configuration already exists for this recording period.",
  },
  // Distribution Criterion
  "distribution_criterion.not_found": {
    errorCode: ErrorCode["distribution_criterion.not_found"],
    message: "The given distribution criterion was not found for the given organization.",
  },
  "distribution_criterion.already_in_use": {
    errorCode: ErrorCode["distribution_criterion.already_in_use"],
    message:
      "The given distribution criterion is already in use by the given organization and can therefore not be deleted.",
  },
  // Applied Distribution Criterion
  "applied_distribution_criterion.not_found": {
    errorCode: ErrorCode["applied_distribution_criterion.not_found"],
    message: "The applied distribution criterion was not found.",
  },
  "applied_distribution_criterion.already_exists": {
    errorCode: ErrorCode["applied_distribution_criterion.already_exists"],
    message:
      "An applied distribution criterion already exists for the given recording period and the given criterion.",
  },
  "applied_distribution_criterion.is_incomplete": {
    errorCode: ErrorCode["applied_distribution_criterion.is_incomplete"],
    message: "The applied distribution criterion is incomplete and can therefore not be applied.",
  },
  "applied_distribution_criterion.already_used_for_value": {
    errorCode: ErrorCode["applied_distribution_criterion.already_used_for_value"],
    message:
      "The applied distribution criterion has already been used for submitting an input value and can therefore no longer be un-applied.",
  },
  "applied_distribution_criterion.already_applied": {
    errorCode: ErrorCode["applied_distribution_criterion.already_applied"],
  },
  // Data Entry Object Distribution Criterion
  "data_entry_object_distribution_criterion.not_found": {
    errorCode: ErrorCode["data_entry_object_distribution_criterion.not_found"],
    message: "The data entry object distribution criterion was not found.",
  },
  // Organization Structure
  "organization_structure.is_in_draft_mode": {
    errorCode: ErrorCode["organization_structure.is_in_draft_mode"],
    message:
      "The specified organization structure is in draft mode. Therefore, the operation cannot be performed.",
  },
  "organization_structure.is_still_ongoing": {
    errorCode: ErrorCode["organization_structure.is_still_ongoing"],
    message:
      "The specified organization structure is ongoing. Therefore, the operation cannot be performed.",
  },
  // Root Data Entry Object
  "root_data_entry_object.not_found": {
    errorCode: ErrorCode["root_data_entry_object.not_found"],
    message: "No root data entry object was not found for the specified recording period.",
  },
  // Reference Budget
  "reference_budget.not_found": {
    errorCode: ErrorCode["reference_budget.not_found"],
    message: "The reference budget was not found for the given organization.",
  },
  "reference_budget.is_used_in_target_path": {
    errorCode: ErrorCode["reference_budget.is_used_in_target_path"],
    message: "The reference budget is used in a target path and can therefore not be deleted",
  },
  "reference_budget.reference_year_not_within_recording_periods": {
    errorCode: ErrorCode["reference_budget.reference_year_not_within_recording_periods"],
    message: "The reference year is not within the specified recording periods.",
  },
  "reference_budget.not_all_target_paths_in_draft_state": {
    errorCode: ErrorCode["reference_budget.not_all_target_paths_in_draft_state"],
    message:
      "Not all referenced target paths are in draft state. Therefore, the current operation is invalid.",
  },
  "reference_budget.recording_structure_does_not_allow_emissions_evaluation": {
    errorCode:
      ErrorCode["reference_budget.recording_structure_does_not_allow_emissions_evaluation"],
    message:
      "The selected recording structure does not allow evaluating its emissions. Therefore, it cannot be used for building a reference budget.",
  },
  // Target Paths
  "target_path.not_found": {
    errorCode: ErrorCode["target_path.not_found"],
    message: "The target path was not found for the given organization.",
  },
  "target_path.state_prohibits_operation": {
    errorCode: ErrorCode["target_path.state_prohibits_operation"],
    message: "The state of the target path prohibits the current operation.",
  },
  "target_path.does_not_meet_criteria_for_commit": {
    errorCode: ErrorCode["target_path.does_not_meet_criteria_for_commit"],
    message: "The target path does not currently meet all the required criteria for committing it.",
  },
  // Target Path Goals
  "target_path_goal.not_found": {
    errorCode: ErrorCode["target_path_goal.not_found"],
    message: "The target path goal was not found for the given target path.",
  },
  "target_path_goal.already_exists_for_year": {
    errorCode: ErrorCode["target_path_goal.already_exists_for_year"],
    message: "A target path goal already exists for the given year and path.",
  },
  "target_path_goal.invalid_target_year": {
    errorCode: ErrorCode["target_path_goal.invalid_target_year"],
    message:
      "The chosen target year is invalid, as it must be greater than the reference year of the associated budget.",
  },
  "target_path_goal.invalid_goal_type_for_referenced_budget_error": {
    errorCode: ErrorCode["target_path_goal.invalid_goal_type_for_referenced_budget_error"],
    message:
      "Relative goals that depend on the revenue are not allowed for reference budgets which do not specify a reference revenue.",
  },
  // Sources
  "source.not_found": {
    errorCode: ErrorCode["source.not_found"],
    message: "The source was not found for the given organization.",
  },
  "source.does_not_meet_requirements": {
    errorCode: ErrorCode["source.does_not_meet_requirements"],
    message:
      "The chosen source does not meet the requirements for being available as a source for a value.",
  },
  "source.is_referenced_in_values": {
    errorCode: ErrorCode["source.is_referenced_in_values"],
    message:
      "The chosen source is referenced in values. Therefore, the current operation is invalid.",
  },
  // Source Files
  "source_file.not_found": {
    errorCode: ErrorCode["source_file.not_found"],
    message: "The source was not found for the given source.",
  },
  "source_file.invalid_state": {
    errorCode: ErrorCode["source_file.invalid_state"],
    message: "The current state of the given source file(s) prohibits the current operation.",
  },
  // S3 Files
  "s3_file.not_found": {
    errorCode: ErrorCode["s3_file.not_found"],
    message: "The S3 file was not found.",
  },
  // Evaluation
  "evaluation.failed": {
    errorCode: ErrorCode["evaluation.failed"],
    message: "The evaluation failed.",
  },
  // Policies
  "policy.not_found": {
    errorCode: ErrorCode["policy.not_found"],
    message:
      "Policy with provided UUID was not found for the provided organization and recording period.",
  },
  // Disclosure Requirements
  "disclosure_requirement.not_found": {
    errorCode: ErrorCode["disclosure_requirement.not_found"],
    message: "The disclosure requirement was not found.",
  },
};
