import {
  Box,
  Breadcrumbs,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  IInputParameterValueMetaDataEsrsTopicIdentifierEnum,
  IPolicy,
  IPolicyData,
  IPolicyGeneralData,
} from "@netcero/netcero-core-api-client";
import { FC, useCallback, useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ErrorTextComponent } from "../../common/components/error-text.component";
import {
  ALL_SECTIONS_CONFIG,
  ALL_SECTIONS_ORDER,
  GENERAL,
  S_VALUES_ONLY,
} from "../policy-inputs.constants";
import { DeleteIcon } from "../../common/constants/tabler-icon.constants";
import { PolicyTopicComponent } from "../policy-topic.component";
import { MdrUtilities } from "../../minimum-disclosure-requirements-common/mdr.utilities";
import { ESRSTopicUtilities, PoliciesUtilities } from "@netcero/netcero-common";
import { useDMACategoriesQuery } from "../../double-materiality-assessment/dma.queries";
import { QueryWrapper } from "../../common/components/query-wrapper.component";
import {
  DialogCancelButton,
  DialogSaveButton,
} from "../../common/dialogs/dialog-button.components";
import { useRenderESRSTopicNameForIdentifier } from "../../double-materiality-assessment/hooks/render-esrs-topic-name-for-identifier.hook";
import { FormatTranslation } from "../../common/components/format-translation.component";
import {
  IPolicyEditDialogData,
  PoliciesAppUtilities,
  PolicySDataOnly,
} from "../policies-app.utilities";

interface IPolicyEditDialogProps {
  open: boolean;
  loading: boolean;
  error?: Error | null;
  disabled?: boolean;
  onClose: (data: IPolicyData | null) => void;
  onDelete: (policyId: string, confirm?: boolean) => void;
  policy?: IPolicy;
  topicIdentifier: IInputParameterValueMetaDataEsrsTopicIdentifierEnum;
  organizationId: string;
  rootDataEntryObjectId: string;
  recordingPeriodId: string;
}

const getFormDefaultValues = (
  policy: IPolicy | undefined,
  identifier: IInputParameterValueMetaDataEsrsTopicIdentifierEnum,
): IPolicyEditDialogData => {
  const identifierKey = PoliciesAppUtilities.getIdentifierKeyForTopic(identifier);

  const result: IPolicyEditDialogData = {
    ...(policy ? PoliciesUtilities.convertPolicyToPolicyData(policy) : {}),
    materialImpactIds: policy?.materialImpactIds ?? [],
    financialEffectIds: policy?.financialEffectIds ?? [],
    general: MdrUtilities.getDefaultValuesForConfiguration<IPolicyGeneralData>(
      GENERAL,
      policy?.general,
    ),
  };

  if (
    // S Sections
    PoliciesAppUtilities.isSTopic(identifierKey)
  ) {
    // S Sections general
    result.s = MdrUtilities.getDefaultValuesForConfiguration<PolicySDataOnly>(
      ALL_SECTIONS_CONFIG["s"],
      policy?.s,
    );
    // S Sections specifics
    result.s[identifierKey] = MdrUtilities.getDefaultValuesForConfiguration(
      ALL_SECTIONS_CONFIG[identifierKey],
      policy?.s?.[identifierKey],
    ) as never;
  } else if (
    // E Sections
    PoliciesAppUtilities.isETopic(identifierKey)
  ) {
    result[identifierKey] = MdrUtilities.getDefaultValuesForConfiguration(
      ALL_SECTIONS_CONFIG[identifierKey],
      policy?.[identifierKey],
    ) as never;
  }

  return result;
};
export const PolicyEditDialog: FC<IPolicyEditDialogProps> = ({
  disabled,
  onClose,
  onDelete,
  error,
  open,
  loading,
  policy,
  topicIdentifier,
  organizationId,
  recordingPeriodId,
  rootDataEntryObjectId,
}) => {
  const mode = policy ? "edit" : "create";
  const identifierKey = useMemo(
    () => PoliciesAppUtilities.getIdentifierKeyForTopic(topicIdentifier),
    [topicIdentifier],
  );
  const { t } = useTranslation("data_entry_object_policy_component");
  const renderTopicName = useRenderESRSTopicNameForIdentifier();

  const dmaCategoriesQuery = useDMACategoriesQuery(
    organizationId,
    recordingPeriodId,
    rootDataEntryObjectId,
  );

  const useFormResult = useForm<IPolicyEditDialogData>({
    defaultValues: getFormDefaultValues(policy, topicIdentifier),
  });

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    reset,
    watch,
  } = useFormResult;

  useEffect(() => {
    if (open) {
      reset(getFormDefaultValues(policy, topicIdentifier));
    }
  }, [open, reset, policy, topicIdentifier]);

  const handleEmitData = useCallback(
    (data: IPolicyEditDialogData) => {
      const result = {
        ...data,
        general: MdrUtilities.convertToApiPayload(data.general, GENERAL),
        financialEffectIds: data.financialEffectIds,
        materialImpactIds: data.materialImpactIds,
      };

      if (PoliciesAppUtilities.isSTopic(identifierKey)) {
        result.s = {
          ...data.s!,
          ...MdrUtilities.convertToApiPayload<S_VALUES_ONLY>(data.s!, ALL_SECTIONS_CONFIG["s"]),
          [identifierKey]: MdrUtilities.convertToApiPayload(
            data.s![identifierKey],
            ALL_SECTIONS_CONFIG[identifierKey],
          ),
        };
      } else if (PoliciesAppUtilities.isETopic(identifierKey)) {
        result[identifierKey] = MdrUtilities.convertToApiPayload(
          data[identifierKey],
          ALL_SECTIONS_CONFIG[identifierKey],
        ) as never;
      } else {
        throw new Error("Unknown/Unhandled topic identifier");
      }

      onClose(result);
    },
    [identifierKey, onClose],
  );

  return (
    <Dialog
      open={open}
      onClose={!isDirty ? () => onClose(null) : undefined}
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle>
        <Box display="flex" alignItems="center" gap={2}>
          <Box flex={1}>
            {/* Breadcrumbs */}
            <Breadcrumbs sx={{ mb: 1 }}>
              <Typography variant="inherit">{`${ESRSTopicUtilities.convertTopicToDisplayTopic(
                topicIdentifier,
              )} - ${renderTopicName(topicIdentifier)}`}</Typography>
            </Breadcrumbs>
            {/* Actual title*/}
            <span>
              <FormatTranslation
                i18nKey={mode === "create" ? "create_policy" : "edit_policy"}
                t={t}
                values={{ title: policy?.general.name }}
              />
            </span>
          </Box>
          {/* Divider */}
          <Divider orientation="vertical" flexItem />
          {/* Delete button */}
          {policy !== undefined && (
            <Tooltip title={t("delete_policy_tooltip")}>
              <span>
                <IconButton onClick={() => onDelete(policy.id, true)} disabled={loading}>
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
        </Box>
      </DialogTitle>
      {loading && <LinearProgress />}
      <DialogContent sx={{ height: "80vh" }}>
        {error && <ErrorTextComponent error={error} />}

        {/* Associated IROs*/}
        <FormProvider {...useFormResult}>
          <QueryWrapper
            query={dmaCategoriesQuery}
            loadingOverride={() => <Skeleton variant="rounded" height={56} sx={{ my: 2 }} />}
            build={(dmaCategories) => (
              <PolicyTopicComponent
                topicPrefix="general"
                control={control}
                inputs={ALL_SECTIONS_ORDER["general"]}
                inputsMetaData={ALL_SECTIONS_CONFIG["general"]}
                watch={watch}
                disabled={loading}
                dmaCategories={dmaCategories}
              />
            )}
          />
        </FormProvider>

        <Divider sx={{ my: 3 }} />

        {PoliciesAppUtilities.isSTopic(identifierKey) ? (
          <>
            <PolicyTopicComponent
              sectionTitle={t("section_titles.esrs_s")}
              topicPrefix="s"
              control={control}
              inputs={ALL_SECTIONS_ORDER["s"]}
              inputsMetaData={ALL_SECTIONS_CONFIG["s"]}
              watch={watch}
              disabled={loading}
            />
            <PolicyTopicComponent
              sectionTitle={t(`section_titles.esrs_${identifierKey}`)}
              topicPrefix={`s.${identifierKey}`}
              control={control}
              inputs={ALL_SECTIONS_ORDER[identifierKey]}
              inputsMetaData={ALL_SECTIONS_CONFIG[identifierKey]}
              watch={watch}
              disabled={loading}
            />
          </>
        ) : (
          <PolicyTopicComponent
            sectionTitle={t(`section_titles.esrs_${identifierKey}`)}
            topicPrefix={identifierKey}
            control={control}
            inputs={ALL_SECTIONS_ORDER[identifierKey]}
            inputsMetaData={ALL_SECTIONS_CONFIG[identifierKey]}
            watch={watch}
            disabled={loading}
          />
        )}
      </DialogContent>
      <DialogActions>
        <DialogCancelButton onClick={() => onClose(null)} disabled={disabled}>
          {t(isDirty ? "cancel" : "close", { ns: "buttons" })}
        </DialogCancelButton>
        <DialogSaveButton onClick={handleSubmit(handleEmitData)} disabled={disabled || !isDirty}>
          {t(mode === "edit" ? "save" : "create", { ns: "buttons" })}
        </DialogSaveButton>
      </DialogActions>
    </Dialog>
  );
};
