import {
  IInputParameter,
  IInputParameterValueConfiguration,
  IInputParameterValueConfigurationSimple,
  IInputParameterValueMetaData,
} from "@netcero/netcero-core-api-client";

const DIRECT_SAVE_VALUE_TYPES: IInputParameterValueMetaData["type"][] = [
  "policy",
  "action",
  "target",
];

export class EsrsValueEditingUtilities {
  public static doesInputParameterStoreValue(inputParameter: IInputParameter) {
    return (
      inputParameter.values[0] &&
      EsrsValueEditingUtilities.doesInputParameterValueStoreValue(
        inputParameter.values[0].valueConfiguration,
      )
    );
  }

  public static doesInputParameterValueStoreValue(
    valueConfiguration: IInputParameterValueConfiguration,
  ): valueConfiguration is IInputParameterValueConfigurationSimple {
    return valueConfiguration.type === "simple";
  }

  public static isInputParameterDirectSave(inputParameter: IInputParameter) {
    if (
      !inputParameter.values[0] ||
      !EsrsValueEditingUtilities.doesInputParameterValueStoreValue(
        inputParameter.values[0].valueConfiguration,
      )
    ) {
      return false;
    }

    return DIRECT_SAVE_VALUE_TYPES.includes(
      inputParameter.values[0]?.valueConfiguration.configuration.type,
    );
  }
}
