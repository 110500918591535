import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { FC, ReactNode } from "react";
import { DataEntryObjectSelectGroupComponent } from "./data-entry-object-select-group.component";
import { IDataEntryObject } from "@netcero/netcero-core-api-client";
import { OrganizationStructureNavigationUtilities } from "../../../organization-structures/navigation/organization-structur-navigation.utilities";
import { DialogCloseButton } from "../../../common/dialogs/dialog-button.components";
import { useTranslation } from "react-i18next";

interface IDataEntryOrganizationDialogProps {
  open: boolean;
  loading?: boolean;
  error?: Error | null;
  disabled?: boolean;
  onClose: VoidFunction;
  title: ReactNode;
  dataEntryObject: IDataEntryObject;
  checkedIds: string[];
  onChangeCheckedIds: (event: string[]) => void;
}

const filterIds = (
  id: string,
  checkedIds: string[],
  checkedParents: {
    id: string;
    anyChildChecked: boolean;
  }[],
) => {
  const newCheckedIds = checkedIds.includes(id)
    ? checkedIds.filter((i) => i !== id)
    : [...checkedIds, id];

  for (const checkedParent of checkedParents) {
    if (!checkedParent.anyChildChecked && !newCheckedIds.includes(checkedParent.id)) {
      newCheckedIds.push(checkedParent.id);
    }
  }

  return newCheckedIds;
};

function areAnyChildrenChecked(dataEntries: IDataEntryObject[], checkedIds: string[]) {
  function checkChildren(entry: IDataEntryObject) {
    if (entry.children && entry.children.length > 0) {
      for (const child of entry.children) {
        if (checkedIds.includes(child.id)) {
          return true;
        }
        if (checkChildren(child)) {
          return true;
        }
      }
    }
    return false;
  }

  return dataEntries.slice(0, -1).map((entry: IDataEntryObject) => ({
    id: entry.id,
    anyChildChecked: checkChildren(entry),
  }));
}

export const DataEntryObjectPickerDialog: FC<IDataEntryOrganizationDialogProps> = ({
  open,
  onClose,
  title,
  dataEntryObject,
  checkedIds,
  onChangeCheckedIds,
  disabled,
}) => {
  const { t } = useTranslation("buttons");

  const handleCheckboxChange = (id: string) => {
    const parentTree = OrganizationStructureNavigationUtilities.getDEOsPathForTreeToChild(
      dataEntryObject,
      id,
    );
    const checkedParents = areAnyChildrenChecked(parentTree!, checkedIds);

    onChangeCheckedIds(filterIds(id, checkedIds, checkedParents));
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DataEntryObjectSelectGroupComponent
          dataEntryGroup={dataEntryObject!}
          checkedIds={checkedIds}
          fieldChange={onChangeCheckedIds}
          handleCheckBoxChange={handleCheckboxChange}
          disabled={disabled}
          initialExpanded
        />
      </DialogContent>
      <DialogActions>
        <DialogCloseButton onClick={onClose}>{t("close")}</DialogCloseButton>
      </DialogActions>
    </Dialog>
  );
};
