import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IDMACategoryWithEffectsAndChildren } from "@netcero/netcero-core-api-client";
import { SearchIcon } from "../../common/constants/tabler-icon.constants";
import { useDebounce } from "@uidotdev/usehooks";
import { IROType } from "../../policies/policies.types";
import { RecursiveUtilities } from "@netcero/netcero-common";
import { CustomTabPanelComponent } from "../../common/components/custom-tab-panel.component";
import { AssociatedIROsTabComponent } from "./associated-iros-tab.component";
import { DialogCloseButton } from "../../common/dialogs/dialog-button.components";

export interface ISelectedIROs {
  selectedFinancialEffectIds: string[];
  selectedMaterialImpactIds: string[];
}

interface IAssociatedIROsDialogProps {
  // General "dialog" data
  open: boolean;
  disabled?: boolean;
  // Base data to allow the dialog to function
  dmaCategories: IDMACategoryWithEffectsAndChildren[];
  // Values + Change handlers
  selectedFinancialEffectIds: string[];
  selectedMaterialImpactIds: string[];
  onChangeFinancialEffectIds: (ids: string[]) => void;
  onChangeMaterialImpactIds: (ids: string[]) => void;
  // Other handlers
  onClose: VoidFunction;
}

interface ITabData {
  categories: IDMACategoryWithEffectsAndChildren[];
  type: IROType;
  doesHaveAnyOfType: boolean;
  checkedIds: string[];
  onChange: (ids: string[]) => void;
}

export const AssociatedIROsDialog: FC<IAssociatedIROsDialogProps> = ({
  disabled,
  onClose,
  open,
  dmaCategories,
  selectedMaterialImpactIds,
  selectedFinancialEffectIds,
  onChangeMaterialImpactIds,
  onChangeFinancialEffectIds,
}) => {
  const { t } = useTranslation("associated_iros_dialog");

  const [activeTab, setActiveTab] = useState(0);
  const [iroSearch, setIROSearch] = useState("");
  const debouncedUsersSearch = useDebounce(iroSearch, 300);

  const flattenedCategories = useMemo(
    () => RecursiveUtilities.flattenRecursiveStructureDown(dmaCategories),
    [dmaCategories],
  );
  const { doAnyMaterialImpactsExist, doAnyFinancialEffectsExist } = useMemo(() => {
    return {
      doAnyMaterialImpactsExist: flattenedCategories.some((c) => c.materialImpacts.length > 0),
      doAnyFinancialEffectsExist: flattenedCategories.some((c) => c.financialEffects.length > 0),
    };
  }, [flattenedCategories]);

  const categoriesWithFilteredIROs = useMemo(() => {
    return flattenedCategories
      .map((category) => {
        return {
          ...category,
          materialImpacts: category.materialImpacts.filter((mi) =>
            mi.title.toLowerCase().includes(debouncedUsersSearch.toLowerCase()),
          ),
          financialEffects: category.financialEffects.filter((fe) =>
            fe.title.toLowerCase().includes(debouncedUsersSearch.toLowerCase()),
          ),
        };
      })
      .filter((c) => c.financialEffects.length > 0 || c.materialImpacts.length > 0);
  }, [flattenedCategories, debouncedUsersSearch]);

  const tabData: ITabData[] = useMemo(() => {
    return [
      {
        type: "materialImpacts",
        categories: categoriesWithFilteredIROs,
        checkedIds: selectedMaterialImpactIds,
        doesHaveAnyOfType: doAnyMaterialImpactsExist,
        onChange: onChangeMaterialImpactIds,
      },
      {
        type: "financialEffects",
        categories: categoriesWithFilteredIROs,
        checkedIds: selectedFinancialEffectIds,
        doesHaveAnyOfType: doAnyFinancialEffectsExist,
        onChange: onChangeFinancialEffectIds,
      },
    ];
  }, [
    categoriesWithFilteredIROs,
    doAnyFinancialEffectsExist,
    doAnyMaterialImpactsExist,
    selectedFinancialEffectIds,
    selectedMaterialImpactIds,
    onChangeFinancialEffectIds,
    onChangeMaterialImpactIds,
  ]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{t("title")}</DialogTitle>
      <DialogContent sx={{ minHeight: "55vh" }}>
        <TextField
          variant="standard"
          size="small"
          placeholder={t("search_placeholder")}
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
          value={iroSearch}
          onChange={(evt) => setIROSearch(evt.currentTarget.value)}
          fullWidth
        />
        <Box display="flex" flexDirection="column" gap={2} mt={1}>
          <Box>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={activeTab}
                onChange={(_, index) => setActiveTab(index)}
                variant="fullWidth"
              >
                {tabData.map((tab, index) => (
                  <Tab key={index} label={t(`tab_titles.${tab.type}`)} />
                ))}
              </Tabs>
            </Box>
            {/* Render the individual tabs */}
            {tabData.map((data, index) => (
              <CustomTabPanelComponent key={index} value={activeTab} index={index}>
                <AssociatedIROsTabComponent
                  categories={data.categories}
                  iroType={data.type}
                  checkedIds={data.checkedIds}
                  doAnyOfTypeExist={data.doesHaveAnyOfType}
                  onChange={data.onChange}
                  disabled={disabled}
                />
              </CustomTabPanelComponent>
            ))}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <DialogCloseButton onClick={onClose}>{t("close", { ns: "buttons" })}</DialogCloseButton>
      </DialogActions>
    </Dialog>
  );
};
