import {
  Box,
  Divider,
  FormControl,
  FormHelperText,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { IInputParameterValueMetaDataMass } from "@netcero/netcero-core-api-client";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DataEntryObjectInputParameterValueDefinitionForMass,
  DataEntryObjectInputParameterValueDefinitionForMassAvailableUnit,
  OptionalDefinition,
} from "@netcero/netcero-common";
import {
  IDataEntryObjectValueInputVariant,
  useVariantFormProps,
  useVariantSxStyles,
} from "../../data-entry-object-values/input-components/data-entry-object-value-input.component";
import { SelectProps } from "@mui/material/Select";

const ORDERED_MASS_UNIT_VALUES = [
  DataEntryObjectInputParameterValueDefinitionForMassAvailableUnit.g,
  DataEntryObjectInputParameterValueDefinitionForMassAvailableUnit.kg,
  DataEntryObjectInputParameterValueDefinitionForMassAvailableUnit.t,
];
const DEFAULT_MASS_UNIT = DataEntryObjectInputParameterValueDefinitionForMassAvailableUnit.kg;

interface INumberInputComponentProps {
  variant: IDataEntryObjectValueInputVariant;
  label?: string;
  value: OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForMass>;
  onChange: (
    value: OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForMass>,
  ) => void;
  onSubmit?: () => void;
  disabled?: boolean;
  error?: string;
  metaData: IInputParameterValueMetaDataMass;
  disableMaxWidth?: boolean;
  required?: boolean;
  autoFocus?: boolean;
}

export const MassInputComponent: FC<INumberInputComponentProps> = ({
  variant,
  label,
  value,
  onChange,
  onSubmit,
  disabled,
  error,
  metaData,
  disableMaxWidth,
  required,
  autoFocus,
}) => {
  const { t } = useTranslation("mass_input");
  const stylesSx = useVariantSxStyles(variant);
  const inputVariantProps = useVariantFormProps(variant);

  const [selectedUnit, setSelectedUnit] = useState(value?.unit ?? DEFAULT_MASS_UNIT);
  useEffect(() => {
    if (value?.unit) {
      setSelectedUnit(value.unit);
    }
  }, [value?.unit]);

  const handleValueChange = useCallback(
    (newValue: string) => {
      const numericValue = +newValue;
      onChange(
        newValue === "" || Number.isNaN(numericValue)
          ? undefined
          : {
              unit: selectedUnit,
              value: numericValue,
            },
      );
    },
    [onChange, selectedUnit],
  );

  const handleUnitChange = useCallback(
    (unit: DataEntryObjectInputParameterValueDefinitionForMassAvailableUnit) => {
      setSelectedUnit(unit);

      // Only emit onChange if value is valid
      if (value?.value !== undefined) {
        onChange({
          unit,
          value: value.value,
        });
      }
    },
    [onChange, value?.value],
  );

  return (
    <FormControl
      {...inputVariantProps}
      error={!!error}
      fullWidth
      sx={{ maxWidth: !disableMaxWidth ? 260 : undefined, minWidth: 250 }}
    >
      <TextField
        required={required}
        label={label}
        {...inputVariantProps}
        type="number"
        placeholder={t("placeholder")}
        value={value?.value}
        disabled={disabled}
        autoFocus={autoFocus}
        error={!!error}
        onChange={(e) => handleValueChange(e.target.value)}
        onKeyDown={(e) => {
          if (e.code === "Enter" || e.code === "NumpadEnter") {
            onSubmit?.();
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Box display="flex" gap={2}>
                <Divider orientation="vertical" flexItem sx={{ borderColor: "#C1C1C1" }} />
                <MassUnitAdornment
                  size={inputVariantProps.size}
                  selectedUnit={selectedUnit}
                  onChange={handleUnitChange}
                />
              </Box>
            </InputAdornment>
          ),
          sx: stylesSx,
        }}
        sx={{ input: { textAlign: !disableMaxWidth ? "right" : undefined } }}
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

interface IMassUnitAdornmentProps {
  size: SelectProps["size"];
  selectedUnit: DataEntryObjectInputParameterValueDefinitionForMassAvailableUnit;
  onChange: (unit: DataEntryObjectInputParameterValueDefinitionForMassAvailableUnit) => void;
}

const MassUnitAdornment: FC<IMassUnitAdornmentProps> = ({ size, selectedUnit, onChange }) => {
  return (
    <Select
      size={size}
      variant="standard"
      disableUnderline
      value={selectedUnit}
      onChange={(evt) =>
        onChange(
          evt.target.value as DataEntryObjectInputParameterValueDefinitionForMassAvailableUnit,
        )
      }
    >
      {ORDERED_MASS_UNIT_VALUES.map((massUnit) => (
        <MenuItem key={massUnit} value={massUnit}>
          {massUnit}
        </MenuItem>
      ))}
    </Select>
  );
};
