import { Box, Button, Divider, IconButton, Tooltip, Typography } from "@mui/material";
import { IOrganization, IRecordingPeriodData } from "@netcero/netcero-core-api-client";
import { useMutation } from "@tanstack/react-query";
import { FC, Fragment, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { OrganizationLineIcon } from "../common/constants/iconify-icon.constants";
import { EditIcon, KeyIcon } from "../common/constants/tabler-icon.constants";
import { FormatUtilities } from "../common/utilities/format.utilities";
import { CoreApiService } from "../core-api/core-api.service";
import { RecordingPeriodEditDialog } from "./recording-period-edit.dialog";
import { getRecordingPeriodsQueryKey } from "./recording-periods.queries";
import { ILocalRecordingPeriod } from "./recording-periods.utilities";
import { HideChildrenInProductionAndNoFullAccessComponent } from "../common/components/hide-children-in-production-and-no-full-access.component";
import { getBaseStakeholderRecordingPeriodsQueryKey } from "../stakeholder-feedbacks/stakeholder-feedbacks.queries";
import { useInvalidateQueriesByKeys } from "../common/hooks/invalidate-queries-by-keys.hook";

interface IRecordingPeriodEditDialogState {
  open: boolean;
  previousRecordingPeriod: ILocalRecordingPeriod | null;
  recordingPeriod: ILocalRecordingPeriod | null;
  nextRecordingPeriod: ILocalRecordingPeriod | null;
}

interface IRecordingPeriodsEditListComponentProps {
  organization: IOrganization;
  recordingPeriods: ILocalRecordingPeriod[];
}

export const RecordingPeriodsEditListComponent: FC<IRecordingPeriodsEditListComponentProps> = ({
  organization,
  recordingPeriods,
}) => {
  const { t } = useTranslation("recording_periods_edit_list_component");

  const navigate = useNavigate();

  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [editDialogState, setEditDialogState] = useState<IRecordingPeriodEditDialogState>({
    open: false,
    previousRecordingPeriod: null,
    recordingPeriod: null,
    nextRecordingPeriod: null,
  });

  const handleClickRecordingPeriod = (recordingPeriodIndex: number) => {
    updateRecordingPeriodMutation.reset();
    setEditDialogState({
      open: true,
      previousRecordingPeriod: recordingPeriods[recordingPeriodIndex + 1] ?? null,
      recordingPeriod: recordingPeriods[recordingPeriodIndex],
      nextRecordingPeriod: recordingPeriods[recordingPeriodIndex - 1] ?? null,
    });
  };

  const invalidateQueriesByKeys = useInvalidateQueriesByKeys();
  const handleMutationOnSuccess = useCallback(
    async (
      _: unknown,
      variables: {
        organizationId: string;
        payload: IRecordingPeriodData;
      },
    ) =>
      invalidateQueriesByKeys({
        awaitQueryKeys: [
          getRecordingPeriodsQueryKey(variables.organizationId),
          getBaseStakeholderRecordingPeriodsQueryKey(),
        ],
      }),
    [invalidateQueriesByKeys],
  );

  const createRecordingPeriodMutation = useMutation({
    mutationFn: async ({
      organizationId,
      payload,
    }: {
      organizationId: string;
      payload: IRecordingPeriodData;
    }) => {
      return CoreApiService.RecordingPeriodApi.createRecordingPeriod(organizationId, payload).then(
        (req) => req(),
      );
    },
    onSuccess: handleMutationOnSuccess,
  });

  const updateRecordingPeriodMutation = useMutation({
    mutationFn: async ({
      organizationId,
      recordingPeriodId,
      payload,
    }: {
      organizationId: string;
      recordingPeriodId: string;
      payload: IRecordingPeriodData;
    }) => {
      return CoreApiService.RecordingPeriodApi.updateRecordingPeriod(
        organizationId,
        recordingPeriodId,
        payload,
      ).then((req) => req());
    },
    onSuccess: handleMutationOnSuccess,
  });

  const onCreateRecordingPeriod = useCallback(() => {
    createRecordingPeriodMutation.reset();
    setShowCreateDialog(true);
  }, [setShowCreateDialog, createRecordingPeriodMutation]);

  const handleCloseCreateDialog = async (data?: ILocalRecordingPeriod) => {
    // Send request if payload is set
    if (data) {
      const result = await createRecordingPeriodMutation.mutateAsync({
        organizationId: organization.id,
        payload: {
          name: data.name,
          description: data.description || undefined,
          startDate: data.startDate.toISOString(),
          endDate: data.endDate.toISOString(),
        },
      });

      // Redirect to organization structure for this recording period
      navigate(
        `/organizations/${organization.id}/configuration/recording-periods/${result.data.id}/organization-structure`,
      );
    }

    // Close Dialog
    setShowCreateDialog(false);
  };

  const handleCloseEditDialog = async (data?: ILocalRecordingPeriod) => {
    // Send request if payload is set
    if (data) {
      await updateRecordingPeriodMutation.mutateAsync({
        organizationId: organization.id,
        recordingPeriodId: editDialogState.recordingPeriod!.id,
        payload: {
          name: data.name,
          description: data.description || undefined,
          startDate: data.startDate.toISOString(),
          endDate: data.endDate.toISOString(),
        },
      });
    }

    // Close Dialog
    setEditDialogState((state) => ({
      ...state,
      open: false,
    }));
  };

  return (
    <>
      {/* Modals */}

      {/* Create Dialog */}
      <RecordingPeriodEditDialog
        open={showCreateDialog}
        onClose={handleCloseCreateDialog}
        organization={organization}
        previousRecordingPeriod={recordingPeriods[0] ?? null}
        loading={createRecordingPeriodMutation.isPending}
        error={
          createRecordingPeriodMutation.isError ? createRecordingPeriodMutation.error : undefined
        }
        disabled={createRecordingPeriodMutation.isPending}
      />
      {/* Edit Dialog */}
      <RecordingPeriodEditDialog
        open={editDialogState.open}
        onClose={handleCloseEditDialog}
        organization={organization}
        previousRecordingPeriod={editDialogState.previousRecordingPeriod}
        recordingPeriod={editDialogState.recordingPeriod}
        nextRecordingPeriod={editDialogState.nextRecordingPeriod}
        loading={updateRecordingPeriodMutation.isPending}
        error={
          updateRecordingPeriodMutation.isError ? updateRecordingPeriodMutation.error : undefined
        }
        disabled={updateRecordingPeriodMutation.isPending}
      />

      {/* Content */}
      <Box maxWidth={1600} mx="auto">
        <Typography variant="h1" mb={2}>
          {t("title")}
        </Typography>

        <Box p={2}>
          <Button variant="contained" sx={{ mb: 3 }} onClick={onCreateRecordingPeriod}>
            {t("button_new_recording_period")}
          </Button>
          {recordingPeriods.length === 0 && (
            <Typography mt={2}>{t("recording_periods_empty")}</Typography>
          )}
          {recordingPeriods.map((recordingPeriod, index) => (
            <Fragment key={recordingPeriod.id}>
              <Box display="flex" alignItems="center" gap={1}>
                <Box>
                  <Typography variant="subtitle1" component="h3">
                    {recordingPeriod.name}
                  </Typography>
                  <Typography variant="body2">
                    {FormatUtilities.formatDate(recordingPeriod.startDate)} -{" "}
                    {FormatUtilities.formatDate(recordingPeriod.endDate)}
                  </Typography>
                </Box>
                <Box flex={1} />
                {/* Recording Period Actions */}
                <Tooltip title={t("button_edit_organization_structure_tooltip")}>
                  <IconButton
                    size="small"
                    component={RouterLink}
                    to={`/organizations/${organization.id}/configuration/recording-periods/${recordingPeriod.id}/organization-structure`}
                  >
                    <OrganizationLineIcon />
                  </IconButton>
                </Tooltip>
                <HideChildrenInProductionAndNoFullAccessComponent>
                  <Tooltip title={t("button_edit_distribution_criteria_tooltip")}>
                    <IconButton
                      size="small"
                      component={RouterLink}
                      to={`/organizations/${organization.id}/configuration/recording-periods/${recordingPeriod.id}/distribution-criteria`}
                    >
                      <KeyIcon />
                    </IconButton>
                  </Tooltip>
                </HideChildrenInProductionAndNoFullAccessComponent>
                <Tooltip title={t("button_edit_recording_period_tooltip")}>
                  <IconButton onClick={() => handleClickRecordingPeriod(index)} size="small">
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <Divider sx={{ my: 1 }} />
            </Fragment>
          ))}
        </Box>
      </Box>
    </>
  );
};
