import { DialogContentText } from "@mui/material";
import { FC, ReactNode, useMemo } from "react";
import { DialogCancelButton, DialogConfirmButton } from "../dialog-button.components";
import { BaseAsyncActionDialog } from "./base-async-action.dialog";
import { IDialogWithOptionalError } from "../types/common.types";
import { useTranslation } from "react-i18next";

interface IConfirmDialogProps extends IDialogWithOptionalError {
  open: boolean;
  loading?: boolean;
  disabled?: boolean;
  onClose: (confirm: boolean) => void;
  title: ReactNode;
  content: ReactNode;
  confirmOverride?: ReactNode;
  cancelOverride?: ReactNode;
}

export const ConfirmDialog: FC<IConfirmDialogProps> = ({
  open,
  loading,
  error,
  disabled,
  onClose,
  title,
  content,
  confirmOverride,
  cancelOverride,
}) => {
  const { t } = useTranslation("buttons");

  return (
    <BaseAsyncActionDialog
      open={open}
      onClose={disabled ? undefined : () => onClose(false)}
      loading={!!loading}
      error={error}
      title={title}
      content={content}
      actions={
        <>
          <DialogCancelButton onClick={() => onClose(false)} disabled={disabled}>
            {cancelOverride ?? t("cancel")}
          </DialogCancelButton>
          <DialogConfirmButton onClick={() => onClose(true)} disabled={disabled}>
            {confirmOverride ?? t("confirm")}
          </DialogConfirmButton>
        </>
      }
    />
  );
};

export const ConfirmDialogTextBody: FC<IConfirmDialogProps> = ({ content, ...props }) => {
  const contentWithWrappedText = useMemo(
    () => <DialogContentText>{content}</DialogContentText>,
    [content],
  );

  return <ConfirmDialog content={contentWithWrappedText} {...props} />;
};
