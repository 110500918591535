import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { IInputParameterValueMetaDataOptions } from "@netcero/netcero-core-api-client";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  useTranslateContent,
  useTranslateOptionalContent,
} from "../../content-translation/hooks/translate-content.hook";
import {
  DataEntryObjectInputParameterValueDefinitionForOptions,
  DataEntryObjectInputParameterValueDefinitionForOptionsMultiple,
  OptionalDefinition,
} from "@netcero/netcero-common";
import {
  IDataEntryObjectValueInputVariant,
  useVariantFormProps,
  useVariantSxStyles,
} from "../../data-entry-object-values/input-components/data-entry-object-value-input.component";
import { LineClampTypographyWithTooltip } from "../../common/components/line-clamp-typography.component";
import { useOptionsLookup } from "../hooks/options-lookup.hook";
import {
  IOptionsSelectPlaceholderProps,
  OptionsSelectPlaceholder,
} from "./options-placeholder.component";

interface IOptionsInputComponentProps {
  variant: IDataEntryObjectValueInputVariant;
  value: OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForOptions>;
  onChange: (
    value: OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForOptions>,
  ) => void;
  disabled?: boolean;
  error?: string;
  metaData: IInputParameterValueMetaDataOptions;
  disableMaxWidth?: boolean;
  label?: string;
  required?: boolean;
}

export const OptionsInputComponent: FC<IOptionsInputComponentProps> = (props) => {
  const variantProps = useVariantFormProps(props.variant);
  const translateOptionalContent = useTranslateOptionalContent();

  const placeholder = useMemo(
    () => translateOptionalContent(props.metaData.placeholder),
    [translateOptionalContent, props.metaData.placeholder],
  );

  const { error, metaData, disableMaxWidth } = props;
  return (
    <FormControl
      {...variantProps}
      error={!!error}
      fullWidth
      sx={{ maxWidth: !disableMaxWidth ? 260 : undefined, minWidth: 200 }}
      required={props.required}
    >
      {props.label && (
        <InputLabel shrink={placeholder ? true : undefined}>{props.label}</InputLabel>
      )}
      {metaData.multiple && <MultiSelectInputComponent {...props} placeholder={placeholder} />}
      {!metaData.multiple && <SingleSelectInputComponent {...props} placeholder={placeholder} />}
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

interface IInternalSelectProps
  extends IOptionsInputComponentProps,
    IOptionsSelectPlaceholderProps {}

const SingleSelectInputComponent: FC<IInternalSelectProps> = ({
  variant,
  value,
  disabled,
  error,
  metaData,
  onChange,
  label,
  required,
  placeholder,
}) => {
  const { t } = useTranslation("options_input_component");
  const translateContent = useTranslateContent();
  const stylesSx = useVariantSxStyles(variant);
  const inputVariantProps = useVariantFormProps(variant);

  return (
    <Select
      {...inputVariantProps}
      value={value ?? ""}
      disabled={disabled}
      error={!!error}
      onChange={(e) => onChange(e.target.value === "" ? undefined : e.target.value)}
      label={label}
      displayEmpty={!!placeholder}
      renderValue={(selectedValue) => {
        const selectedOption = metaData.options.find((option) => option.value === selectedValue);

        if (selectedOption === undefined) {
          return <OptionsSelectPlaceholder placeholder={placeholder} />;
        }

        return translateContent(selectedOption.name);
      }}
      sx={stylesSx}
    >
      {!required && <MenuItem value="">{t("name_empty_option")}</MenuItem>}
      {metaData.options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {translateContent(option.name)}
        </MenuItem>
      ))}
    </Select>
  );
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const toDefinedMulti = (
  definition: OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForOptions>,
) =>
  (definition as OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForOptionsMultiple>) ??
  [];

const MultiSelectInputComponent: FC<IInternalSelectProps> = ({
  variant,
  value,
  error,
  disabled,
  onChange,
  metaData,
  label,
  placeholder,
}) => {
  const translateContent = useTranslateContent();
  const variantProps = useVariantFormProps(variant);
  const stylesSx = useVariantSxStyles(variant);

  const generatedOptions = useMemo(() => {
    const allValues = toDefinedMulti(value);
    return metaData.options.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          <Checkbox checked={allValues.includes(option.value)} />
          <ListItemText
            primary={
              <LineClampTypographyWithTooltip>
                {translateContent(option.name)}
              </LineClampTypographyWithTooltip>
            }
          />
        </MenuItem>
      );
    });
  }, [metaData.options, translateContent, value]);

  const lookup = useOptionsLookup(metaData);

  return (
    <Select
      label={label}
      value={toDefinedMulti(value)}
      error={!!error}
      multiple
      {...variantProps}
      disabled={disabled}
      MenuProps={MenuProps}
      onChange={({ target: { value } }: SelectChangeEvent<string[]>) => {
        const newValue = typeof value === "string" ? value.split(",") : value;
        onChange(newValue);
      }}
      displayEmpty={!!placeholder}
      renderValue={(selected) => {
        if (selected.length === 0) {
          return <OptionsSelectPlaceholder placeholder={placeholder} />;
        }

        return (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={lookup[value] || "ERROR: Unknown Value"} />
            ))}
          </Box>
        );
      }}
      sx={stylesSx}
    >
      {generatedOptions}
    </Select>
  );
};
