import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ErrorTextComponent } from "../common/components/error-text.component";
import {
  DialogCancelButton,
  DialogPublishButton,
} from "../common/dialogs/dialog-button.components";

export interface IOrganizationStructureDraftCommitDialogProps {
  open: boolean;
  loading?: boolean;
  error?: Error;
  onClose: (commit: true | null) => void;
  disabled?: boolean;
}

export const OrganizationStructureDraftCommitDialog: FC<
  IOrganizationStructureDraftCommitDialogProps
> = ({ open, loading, error, onClose, disabled }) => {
  const { t } = useTranslation("organization_structure_draft_commit_dialog");

  return (
    <Dialog open={open} maxWidth="sm">
      <DialogTitle>{t("title")}</DialogTitle>
      {loading && <LinearProgress />}
      <DialogContent>
        {error && (
          <Box mb={1}>
            <ErrorTextComponent error={error} />
          </Box>
        )}
        <Typography variant="body1">{t("warning_text")}</Typography>
      </DialogContent>
      <DialogActions>
        <DialogCancelButton onClick={() => onClose(null)} disabled={disabled}>
          {t("button_cancel")}
        </DialogCancelButton>
        <DialogPublishButton onClick={() => onClose(true)} disabled={disabled}>
          {t("button_publish")}
        </DialogPublishButton>
      </DialogActions>
    </Dialog>
  );
};
