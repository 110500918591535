import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  TextField,
  Tooltip,
} from "@mui/material";
import { IDMACategory } from "@netcero/netcero-core-api-client";
import { FC, useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ErrorTextComponent } from "../../../common/components/error-text.component";
import { DeleteIcon } from "../../../common/constants/tabler-icon.constants";
import { IEditDialogProps } from "../../../common/dialogs/types/edit-dialog.interface";
import {
  DialogCancelButton,
  DialogSaveButton,
} from "../../../common/dialogs/dialog-button.components";

export type IEditDMACategoryFormData = Pick<IDMACategory, "name">;

interface IEditDMACategoryDialogProps extends IEditDialogProps<IEditDMACategoryFormData> {
  dmaCategory?: IDMACategory | null;
  canDelete: boolean;
}

const getDefaultValuesForInputs = (dmaCategory?: IDMACategory | null): IEditDMACategoryFormData => {
  return {
    name: dmaCategory?.name ?? "",
  };
};

export const EditDMACategoryDialog: FC<IEditDMACategoryDialogProps> = ({
  dmaCategory,
  open,
  onClose,
  onDelete,
  disabled,
  loading,
  error,
  canDelete,
}) => {
  const { t } = useTranslation("dma_category_edit_dialog");

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty },
  } = useForm<IEditDMACategoryFormData>({
    defaultValues: getDefaultValuesForInputs(dmaCategory),
  });

  // Reset Dialog when opened
  useEffect(() => {
    if (open) {
      reset(getDefaultValuesForInputs(dmaCategory));
    }
  }, [open, dmaCategory, reset]);

  // Close Handling (Data Emission)
  const handleEmitData = useCallback(
    (data: IEditDMACategoryFormData) => {
      onClose({ name: data.name.trim() });
    },
    [onClose],
  );

  return (
    <Dialog
      open={open}
      onClose={!isDirty ? () => onClose(null) : undefined}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle display="flex" alignItems="center">
        {/* Title Text (no need to translate since always custom) */}
        {dmaCategory ? t("title_edit", { name: dmaCategory.name }) : t("title_create")}
        {/* Delete Button */}
        {onDelete && (
          <Tooltip title={canDelete ? t("delete_tooltip") : t("cannot_delete_tooltip")}>
            <Box component="span" ml="auto">
              <IconButton size="small" onClick={onDelete} disabled={disabled || !canDelete}>
                <DeleteIcon />
              </IconButton>
            </Box>
          </Tooltip>
        )}
      </DialogTitle>
      {loading && <LinearProgress />}
      <DialogContent>
        {error && <ErrorTextComponent error={error} />}

        <Box display="flex" flexDirection="column" gap={2} py={1}>
          {/* Basic Properties Inputs */}
          {/* Name */}
          <Controller
            control={control}
            name="name"
            rules={{
              required: t("error_name_required"),
              validate: (value) => (value.trim() === "" ? t("error_name_required") : true),
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("name_label")}
                error={!!errors.name}
                helperText={errors.name?.message}
                fullWidth
                disabled={disabled}
              />
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <DialogCancelButton onClick={() => onClose(null)} disabled={disabled}>
          {t("button_cancel")}
        </DialogCancelButton>
        <DialogSaveButton onClick={handleSubmit(handleEmitData)} disabled={!isDirty || disabled}>
          {dmaCategory ? t("button_save") : t("button_create")}
        </DialogSaveButton>
      </DialogActions>
    </Dialog>
  );
};
