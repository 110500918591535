import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Select,
  styled,
  SvgIcon,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { IDMAConfiguration, IHorizonsEnum } from "@netcero/netcero-core-api-client";
import { FinancialEffectCalculator } from "@netcero/netcero-dma";
import { FC, useEffect, useMemo, useState } from "react";
import {
  Control,
  Controller,
  UseFormSetValue,
  useFormState,
  UseFormTrigger,
  useWatch,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormatTranslation } from "../../common/components/format-translation.component";
import { OptionsDialog } from "../../common/dialogs/variants/options.dialog";
import {
  CancelIcon,
  EditIcon,
  InfoIcon,
  OpenInNewTabIcon,
} from "../../common/constants/tabler-icon.constants";
import { ChipListInput } from "../../common/inputs/chip-list-input.component";
import { FormatUtilities } from "../../common/utilities/format.utilities";
import { DMAPotentialExtentSlider } from "../common/dma-extent-slider.component";
import { ORDERED_HORIZONS_VALUES } from "../common/dma-horizons.constants";
import { IInheritFromHorizonsState } from "../common/dma-horizons.interfaces";
import { DMAProbabilitySlider } from "../common/dma-probability-slider.component";
import { ORDERED_EFFECT_TYPES } from "../common/dma.constants";
import { IInternalFinancialEffectData } from "./dma-financial-effect-edit.dialog";
import { useSyncCategoryToLS } from "../hooks/sync-category-to-ls.hook";

const MIN_WIDTH_SLIDER_LABEL = 240;

const SliderContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: { flexWrap: "wrap" },
  [theme.breakpoints.up("md")]: { flexWrap: "nowrap" },
  paddingRight: theme.spacing(4),
}));

interface IDMAFinancialEffectHorizonEditSectionProps {
  horizon: IHorizonsEnum;
  inheritFromHorizonsState: IInheritFromHorizonsState;
  dmaConfiguration: IDMAConfiguration;
  control: Control<IInternalFinancialEffectData>;
  showHorizonsCommentInput: boolean;
  disabled?: boolean;
  setValue: UseFormSetValue<IInternalFinancialEffectData>;
  trigger: UseFormTrigger<IInternalFinancialEffectData>;
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
}

export const DMAFinancialEffectHorizonEditSection: FC<
  IDMAFinancialEffectHorizonEditSectionProps
> = ({
  horizon,
  inheritFromHorizonsState,
  dmaConfiguration,
  control,
  showHorizonsCommentInput,
  disabled: parentDisabled,
  setValue,
  trigger,
  recordingPeriodId,
  organizationId,
  dataEntryObjectId,
}) => {
  const { t } = useTranslation("dma_financial_effect_edit_dialog");
  const { isSubmitted } = useFormState({ control });

  const [showEffectTypeExplanationDialog, setShowEffectTypeExplanationDialog] = useState(false);

  const { clearSettingsForDataEntryObject } = useSyncCategoryToLS();

  const horizonIsInUseByOtherHorizon = useMemo(
    () => inheritFromHorizonsState[horizon].inUse,
    [inheritFromHorizonsState, horizon],
  );
  const inheritsFromHorizon = useWatch({
    control,
    name: `horizons.${horizon}.inheritsFromHorizon`,
  });

  const displayHorizon = inheritsFromHorizon ?? horizon;

  const currentAdditionalEffects = useWatch({
    control,
    name: `horizons.${displayHorizon}.additionalEffects`,
  });
  useEffect(() => {
    if (isSubmitted) {
      void trigger(`horizons.${displayHorizon}.effectType`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitted, currentAdditionalEffects]);

  const internalDisabled = parentDisabled || inheritsFromHorizon !== null;

  const currentProbabilityOfOccurrence = useWatch({
    control,
    name: `horizons.${displayHorizon}.probabilityOfOccurrence`,
  });
  const currentPotentialExtent = useWatch({
    control,
    name: `horizons.${displayHorizon}.potentialExtent`,
  });

  const [calculatedSeverityCurrency, calculatedMaterialityDegree, calculatedMateriality] =
    useMemo(() => {
      const calculator = new FinancialEffectCalculator(
        {
          potentialExtent: currentPotentialExtent,
          probabilityOfOccurrence: currentProbabilityOfOccurrence,
        },
        {
          materialityThreshold: dmaConfiguration.materialityThreshold,
          probabilityOfOccurrenceSteps:
            dmaConfiguration.probabilityOfOccurrenceStepsFinancial ?? null,
        },
      );
      return [
        dmaConfiguration.potentialExtentSteps
          ? calculator.calculateSeverityCurrency(dmaConfiguration.potentialExtentSteps)
          : null,
        calculator.calculateMaterialityDegree(),
        calculator.calculateMateriality(),
      ];
    }, [dmaConfiguration, currentProbabilityOfOccurrence, currentPotentialExtent]);

  const overrideMateriality = useWatch({
    control,
    name: `horizons.${displayHorizon}.overrideMateriality`,
  });

  const handleEditMateriality = () => {
    setValue(`horizons.${displayHorizon}.overrideMateriality`, true, { shouldDirty: true });
  };

  const handleCancelUserMateriality = () => {
    setValue(`horizons.${displayHorizon}.overrideMateriality`, false, { shouldDirty: true });
  };

  return (
    <>
      <OptionsDialog
        open={showEffectTypeExplanationDialog}
        onClose={() => setShowEffectTypeExplanationDialog(false)}
        title={t("explanation_dialog_title", { ns: "common" })}
        content={<FormatTranslation t={t} i18nKey="subtitle_effect_type_info" />}
        options={[
          {
            label: t("close", { ns: "buttons" }),
            onClick: () => setShowEffectTypeExplanationDialog(false),
          },
        ]}
      />

      <Box display="flex" flexDirection="column" gap={2} px={1}>
        {/* Inherit from Horizon */}
        <Box display="flex" alignItems="center" gap={2}>
          <FormLabel>
            {t("inherit_form_horizon_select_label", {
              ns: "dma_impact_or_effect_edit_dialog_common",
            })}
          </FormLabel>
          <Controller
            control={control}
            name={`horizons.${horizon}.inheritsFromHorizon`}
            render={({ field }) => (
              <Tooltip
                title={
                  horizonIsInUseByOtherHorizon
                    ? t("in_use_by_other_horizon", {
                        ns: "dma_impact_or_effect_edit_dialog_common",
                      })
                    : ""
                }
                placement="right"
              >
                <span>
                  <FormControl>
                    <Select
                      variant="standard"
                      disableUnderline
                      value={field.value ?? "no_inheritance"}
                      onChange={(evt) =>
                        field.onChange(
                          evt.target.value === "no_inheritance" ? null : evt.target.value,
                        )
                      }
                      disabled={parentDisabled || horizonIsInUseByOtherHorizon}
                    >
                      <MenuItem value="no_inheritance">
                        {t("no_inheritance_name", {
                          ns: "dma_impact_or_effect_edit_dialog_common",
                        })}
                      </MenuItem>
                      {ORDERED_HORIZONS_VALUES.filter(
                        (inherit_horizon_value) => inherit_horizon_value !== horizon,
                      ).map((inherit_horizon_value) => {
                        const inheritsValuesItself =
                          inheritFromHorizonsState[inherit_horizon_value].inheritsValues;

                        return (
                          <MenuItem
                            key={inherit_horizon_value}
                            value={inherit_horizon_value}
                            disabled={inheritsValuesItself}
                          >
                            {t(`inherit_from_${inherit_horizon_value}`, {
                              ns: "dma_impact_or_effect_edit_dialog_common",
                            })}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </span>
              </Tooltip>
            )}
          />
        </Box>

        {/* Horizons Comment - only shown if horizons have different values */}
        {showHorizonsCommentInput && (
          <Controller
            control={control}
            name="horizonsComment"
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label={t("horizons_comment_label", {
                  ns: "dma_impact_or_effect_edit_dialog_common",
                })}
                InputProps={{
                  endAdornment: (
                    <Tooltip
                      title={t("horizons_comment_explanation", {
                        ns: "dma_impact_or_effect_edit_dialog_common",
                      })}
                    >
                      <SvgIcon fontSize="small" color="action" sx={{ mb: "auto" }}>
                        <InfoIcon />
                      </SvgIcon>
                    </Tooltip>
                  ),
                }}
                error={!!error}
                helperText={error?.message}
                multiline
                minRows={1}
                fullWidth
                disabled={parentDisabled}
              />
            )}
          />
        )}

        {/* Inputs with actual used values */}
        {/* Type Checkboxes */}
        {/* Note: validation for this property (as well as for custom effects) is dealt with when sharing IRO! */}
        <Controller
          control={control}
          name={`horizons.${displayHorizon}.effectType`}
          render={({ field, fieldState: { error } }) => (
            <Box mt={3}>
              <Box display="flex" alignItems="center" gap={1}>
                <Typography variant="subtitle1">{t("subtitle_effect_type")}</Typography>
                <Tooltip
                  title={t("show_explanation_button_tooltip", { ns: "buttons" })}
                  placement="right"
                >
                  <IconButton
                    onClick={() => setShowEffectTypeExplanationDialog(true)}
                    size="small"
                    color="inherit"
                  >
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              {error && <FormHelperText error>{error.message}</FormHelperText>}
              <Grid container alignItems="center">
                {ORDERED_EFFECT_TYPES.map((effectType) => (
                  <Grid item xs={12} md={6} lg={4} key={effectType}>
                    <FormControlLabel
                      label={t(`effect_type.${effectType}`, {
                        ns: "dma_financial_effect_general",
                      })}
                      control={
                        <Checkbox
                          size="small"
                          onChange={(_, checked) => {
                            if (checked) {
                              field.onChange([...field.value, effectType]);
                            } else {
                              field.onChange(field.value.filter((value) => value !== effectType));
                            }
                          }}
                          checked={field.value.includes(effectType)}
                          disabled={internalDisabled}
                        />
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        />
        {/* Additional Types */}
        <Box display="flex" gap={2} mt={1} mb={3}>
          <Typography variant="subtitle2" pt={0.85}>
            {t("subtitle_additional_effect_types")}
          </Typography>
          <Controller
            control={control}
            name={`horizons.${displayHorizon}.additionalEffects`}
            render={({ field }) => (
              <ChipListInput
                value={field.value}
                onChange={(value) => field.onChange(value)}
                disabled={internalDisabled}
              />
            )}
          />
        </Box>

        {/* Probability */}
        <SliderContainer>
          <FormLabel sx={{ minWidth: MIN_WIDTH_SLIDER_LABEL }}>
            {t("label_probability_of_occurrence")}
          </FormLabel>
          <Controller
            control={control}
            name={`horizons.${displayHorizon}.probabilityOfOccurrence`}
            render={({ field }) => (
              <DMAProbabilitySlider
                value={field.value}
                onChange={(value) => field.onChange(value)}
                aria-label={t("label_probability_of_occurrence")}
                disabled={internalDisabled}
              />
            )}
          />
        </SliderContainer>

        {/* Potential Extends */}
        <SliderContainer>
          <FormLabel sx={{ minWidth: MIN_WIDTH_SLIDER_LABEL }}>
            {t("label_potential_extent")}
          </FormLabel>
          <Controller
            control={control}
            name={`horizons.${displayHorizon}.potentialExtent`}
            render={({ field }) => (
              <DMAPotentialExtentSlider
                dmaConfiguration={dmaConfiguration}
                value={field.value}
                onChange={(_, value) => field.onChange(value as number)}
                aria-label={t("label_potential_extent")}
                disabled={internalDisabled}
              />
            )}
          />
        </SliderContainer>

        {/* Evaluation */}
        <Typography variant="subtitle1" component="h3">
          {t("header_horizon_evaluation", { ns: "dma_impact_or_effect_edit_dialog_common" })}
        </Typography>

        {/* Severity Currency */}
        <Box display="flex" gap={2}>
          <Typography sx={{ minWidth: 180 }}>
            {t("severity_currency_label", { ns: "dma_impact_or_effect_edit_dialog_common" })}:
          </Typography>
          {calculatedSeverityCurrency !== null ? (
            <Typography fontWeight="bold">
              {FormatUtilities.formatCurrency(calculatedSeverityCurrency)}
            </Typography>
          ) : (
            <Box display="flex" flexDirection="row" gap={0.5}>
              <Typography fontWeight="light">
                {t("no_equivalent_set_1", { ns: "dma_impact_or_effect_edit_dialog_common" })}
              </Typography>
              <Link
                href={`/organizations/${organizationId}/recording-periods/${recordingPeriodId}/double-materiality-assessment`}
                target="_blank"
                fontWeight="bold"
                rel="noopener noreferrer"
                onClick={() => {
                  clearSettingsForDataEntryObject(dataEntryObjectId);
                }}
              >
                <Box display="flex" gap={0.5}>
                  {t("no_equivalent_set_2", { ns: "dma_impact_or_effect_edit_dialog_common" })}
                  <OpenInNewTabIcon />
                </Box>
              </Link>
              <Typography fontWeight="light">
                {t("no_equivalent_set_3", { ns: "dma_impact_or_effect_edit_dialog_common" })}
              </Typography>
            </Box>
          )}
        </Box>

        {/* Materiality Degree */}
        <Box display="flex" gap={2}>
          <Typography sx={{ minWidth: 180 }}>
            {t("materiality_degree_label", { ns: "dma_impact_or_effect_edit_dialog_common" })}:
          </Typography>
          <Typography fontWeight="bold">
            {FormatUtilities.formatFixedDecimal(calculatedMaterialityDegree, 1)}
          </Typography>
        </Box>

        {/* Materiality */}
        {!overrideMateriality ? (
          /* Default Materiality (calculated) */
          <Box display="flex" alignItems="center" gap={2}>
            <Typography sx={{ minWidth: 180 }}>
              {t("materiality_label", { ns: "dma_impact_or_effect_edit_dialog_common" })}:
            </Typography>
            <Typography fontWeight="bold">
              {calculatedMateriality
                ? t("materiality_true_state", { ns: "dma_common" })
                : t("materiality_false_state", { ns: "dma_common" })}
            </Typography>
            <Tooltip
              title={t("edit_materiality_tooltip", {
                ns: "dma_impact_or_effect_edit_dialog_common",
              })}
            >
              <span>
                <IconButton
                  size="small"
                  onClick={handleEditMateriality}
                  disabled={internalDisabled}
                >
                  <EditIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        ) : (
          /* User Materiality (override value) */
          <Box>
            <Box display="flex" gap={2}>
              <Typography sx={{ minWidth: 150 }}>
                {t("calculated_materiality_label", {
                  ns: "dma_impact_or_effect_edit_dialog_common",
                })}
                :
              </Typography>
              <Typography>
                {calculatedMateriality
                  ? t("materiality_true_state", { ns: "dma_common" })
                  : t("materiality_false_state", {
                      ns: "dma_common",
                    })}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={2} my={1}>
              <Typography fontWeight="bold" sx={{ minWidth: 150 }}>
                {t("custom_materiality_label", { ns: "dma_impact_or_effect_edit_dialog_common" })}:
              </Typography>
              <Controller
                control={control}
                name={`horizons.${displayHorizon}.materialityUser`}
                render={({ field }) => (
                  <Box display="flex" alignItems="center">
                    <Typography fontWeight={!field.value ? "bold" : undefined}>
                      {t("materiality_false_state", {
                        ns: "dma_common",
                      })}
                    </Typography>
                    <Switch
                      checked={field.value!}
                      onChange={(_, checked) => field.onChange(checked)}
                      disabled={internalDisabled}
                    />
                    <Typography fontWeight={field.value ? "bold" : undefined}>
                      {t("materiality_true_state", { ns: "dma_common" })}
                    </Typography>
                  </Box>
                )}
              />
              <Tooltip
                title={t("cancel_edit_materiality_tooltip", {
                  ns: "dma_impact_or_effect_edit_dialog_common",
                })}
              >
                <span>
                  <IconButton
                    size="small"
                    onClick={handleCancelUserMateriality}
                    disabled={internalDisabled}
                  >
                    <CancelIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Box>
            <Controller
              control={control}
              name={`horizons.${displayHorizon}.userMaterialityReason`}
              rules={{
                required: t("error_user_materiality_reason_required", {
                  ns: "dma_impact_or_effect_edit_dialog_common",
                }),
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label={t("label_user_materiality_reason", {
                    ns: "dma_impact_or_effect_edit_dialog_common",
                  })}
                  error={!!error}
                  helperText={error?.message}
                  fullWidth
                  disabled={internalDisabled}
                />
              )}
            />
          </Box>
        )}
      </Box>
    </>
  );
};
